import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Scrollspy from 'vue2-scrollspy';
import BootstrapVue from 'bootstrap-vue'
import VueYoutube from 'vue-youtube'
import VueParticles from 'vue-particles'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'

var VueScrollTo = require('vue-scrollto');


Vue.use(VueParticles)
Vue.use(VueYoutube)
Vue.use(VueScrollTo)
Vue.use(ElementUI);
Vue.use(BootstrapVue);

Vue.use(Scrollspy);
Vue.config.productionTip = false;
Vue.prototype.toWhatsApp = () => {
	window.location.href = 'https://wa.me/123456'
}
new Vue({
	router,
	render: h => h(App),
	store
}).$mount("#app");