/*
 * @Author: daidai
 * @Date: 2021-12-06 10:58:24
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-04-27 16:54:32
 * @FilePath: \web-pc\src\config\UtilVar.js
 */
var UtilVar = {
	ENC: false, //返回结果是否加密s
	baseUrl: `https://giftcard.lengex.com/gateway/qingfeng/`,
	//baseUrl: `http://127.0.0.1:8088/screen/api/`,
	code: 401,
}
const runtimeType = {

	production: () => {},
	yh: () => {

	},
	//开发环境
	development: () => {

	},
}
//runtimeType[process.env.VUE_APP_URL_ENV]()
export default UtilVar
