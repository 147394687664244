import Vue from 'vue'
import Vuex from 'vuex'
import {
	currentGET
} from "@/api/index";
// 应用vuex插件
Vue.use(Vuex)

// 创建并暴露store
export default new Vuex.Store({
	// 数据,相当于data
	state: {
		chats: [],
		chatIndex: 0
	},
	//准备getters——用于将state中的数据进行加工
	getters: {

	},
	//准备mutations——用于操作数据（state）
	mutations: {
		setChats(state, {
			datas
		}) {
			state.chats = datas.map(p => p.chatTel);
			state.chatIndex = 0;
		},
		toChat(state) {
			window.open('https://wa.me/' + state.chats[state.chatIndex])
			console.log();
			if (state.chatIndex === (state.chats.length - 1)) {
				state.chatIndex = 0
			} else {
				state.chatIndex = state.chatIndex + 1
			}
		}
	},
	//准备actions——用于响应组件中的动作
	actions: {
		loadChats({
			commit
		}) {
			currentGET('chats').then(res => {
				let datas = res.data || []
				commit('setChats', {
					datas
				})
			})
		},
		clickChat({
			commit
		}) {
			commit('toChat')
		}
	},
	modules: {


	}
})