<script>
	import {
		currentGET
	} from "@/api/index";
	import {
		mapActions
	} from "vuex"
	export default {
		name: 'Rate',
		components: {},
		computed: {
			calcResult() {
				if (this.form.number && this.form.cardAmount) {
					let result = this.form.number * this.form.cardAmount
					return result.toFixed(2)
				}
				return '0'
			}
		},
		data() {
			return {
				form: {},
				cards: [],
				categorys: [],
				scrollImgs: [
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAggZicrAYo7PbJlgYw2AE4Rg',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg-5ecrAYogtep3gUw4Bc4oAY',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg-ZecrAYo8rOBwQMwoAY42gE',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg-JecrAYozcPP-gIwqQk4qQk',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg_pecrAYozNnU7QQwoAY4yAI',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg_JecrAYonJ3jjAQwoAY4qgE',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg95ecrAYo6KyrzQEwnQY4sgE',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg9ZecrAYogYWp1gQwsxk4ygc',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg85ecrAYozvCKmAQwjxE4zQY',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg8pecrAYovZeo8AIwrRg4uA4',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg8JecrAYop5XDkQIwsAk4rgk',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg7pecrAYowIT99gQwjgs4hAQ',
					'https://27962583.s21i.faimallusr.com/4/ABUIABACGAAg7JecrAYotdOW9AYwgA84uAg',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg6pecrAYogKHqigIwsAk42QU',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg6ZecrAYo9oqVoQMw9hM47gU',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg55ecrAYo7qOcYDCOFjjtBg',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg5ZecrAYokLGKhgQw8AQ41gE',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg5JecrAYoivu2sgUwrAI4eA',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg4pecrAYooJrNhwQwkAM4UA',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg4JecrAYo1sPdAjCEBzjjAw',
					'https://27962583.s21i.faimallusr.com/4/ABUIABAEGAAg35ecrAYouqropAMw0h04oQc'
				]
			}
		},
		created() {
			this.loadCardData();
		},
		methods: {
			...mapActions(['loadChats', 'clickChat']),
			loadCardData() {
				this.cards = [];
				currentGET("cards").then((res) => {
					if (res.code == 200) {
						this.categorys = res.data;
					}
				});
			},
			cateChange(e) {
				let cate = this.categorys.find(p => p.categoryId === e);
				this.cards = cate.cardVoList || [];
				this.form.amount = null
			},
			toChat() {
				this.clickChat()
			},
		}
	}
</script>
<template>
	<!--START PRICING-->
	<section class="section bg-green" id="rates">
		<div class="container">
			<div class="box">
				<ul class="scroll-images">
					<li class="image-li" v-for="(img,index) in scrollImgs" :key="index">
						<img :src="img">
					</li>
				</ul>
				<ul class="scroll-images">
					<li class="image-li" v-for="(img,index) in scrollImgs" :key="index">
						<img :src="img">
					</li>
				</ul>
			</div>

			<div class="row margin-t-20">
				<div class="col-lg-8 offset-lg-2">
					<h1 class=" font-white text-center">Rate Calculator</h1>
					<h3 class=" font-white text-center"> click To Sell to check the latest price on WhatsApp
					</h3>
					<!-- <div class="section-title-border margin-t-20"></div> -->
					<el-form ref="form" :model="form" label-width="200px" class="margin-t-30" label-position="top">
						<el-form-item label="Gift Card Category">
							<el-select v-model="form.categoryId" placeholder="Please Choose Category" class="w-100"
								@change="cateChange">
								<el-option v-for="(cate,index) in categorys" :key="cate.categoryId"
									:label="cate.categoryName" :value="cate.categoryId"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="Gift Card">
							<el-select v-model="form.cardAmount" placeholder="Please Choose Card" class="w-100">
								<el-option v-for="(card,index) in cards" :key="card.cardId" :label="card.cardName"
									:value="card.cardAmount"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="Gift Card Category">
							<el-input v-model="form.number" placeholder="Please Enter Amount"></el-input>
						</el-form-item>
						<el-form-item>
							<div class="text-white result">Nigerian Naira ₦ <span
									class=" calcResult">{{calcResult}}</span></div>
						</el-form-item>
						<el-form-item style="text-align: center;">
							<el-button type="warning" class="sell" round @click="toChat">To Sell</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</section>
	<!--ENd PRICING-->
</template>
<style scoped>
	.bg-green {
		background: #005C97;
		/* fallback for old browsers */
		background: linear-gradient(to right, #363795, #005C97);
	}

	.font-white {
		color: #fff !important;
	}

	::v-deep .el-form-item__label {
		color: #FFFFFF;
		font-size: 18px;
	}

	::v-deep .el-form--label-top .el-form-item__label {
		padding: 0;
	}

	.w-100 {
		width: 100%;
	}

	.result {
		font-size: 25px;
	}

	.calcResult {
		font-size: 30px;
		color: #E6A23C;
	}

	::v-deep .sell {
		width: 200px;
		font-size: 22px;
	}

	.scroll-images {
		display: inline-block;
		animation: toScrool 20s linear 1s infinite normal;
		overflow: hidden;
		width: 1200px
	}

	@keyframes toScrool {
		0% {
			transform: translate(0)
		}

		to {
			transform: translate(-1200px)
		}
	}

	.image-li {
		cursor: pointer;
		height: 40px;
		line-height: 40px;
		padding-right: 50px;
		display: inline-block;

	}

	.image-li img {
		height: 32px;
	}

	.box {
		overflow: hidden;
		width: 2400px;
		height: 40px;

	}

	.container {
		overflow: hidden;
	}
</style>