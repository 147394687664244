<script>
	export default {
		name: 'Services'
	}
</script>

<template>
	<div>
		<!--START SERVICES-->
		<section class="section bg-light" id="rewards">
			<div class="container">
				<div class="row">
					<div class="col-lg-8 offset-lg-2">
						<h1 class=" text-center">Referral Reward</h1>
						<div class="section-title-border margin-t-20"></div>
						<p class="section-subtitle text-muted text-center padding-t-30 font-secondary">The QingFeng
							Rewards Program has two different parts: the Referral Program, and the Monthly Transaction
							Program. Each of these programs come with different benefits and have different requirements
							to be approved.
							Here's a break down of what makes up the QingFeng Rewards Program:</p>
					</div>
				</div>
				<div class="row margin-t-30">
					<div class="col-lg-4 margin-t-20">
						<div class="services-box">
							<div class="media">
								<i class="pe-7s-diamond text-custom"></i>
								<div class="media-body ml-4">
									<h4>Benefits</h4>
									<p class="pt-2 text-muted">Each of our programs have a series of levels that come
										with different benefits.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4 margin-t-20">
						<div class="services-box">
							<div class="media">
								<i class="pe-7s-display2 text-custom"></i>
								<div class="media-body ml-4">
									<h4>Referral Program
									</h4>
									<p class="pt-2 text-muted">You refer your friends to trade with us, and we will
										reward you when your friend trading volume reaches a certain amount. The more
										you refer, the more rewards you may get. Rewards are counted individually for
										each referee. The more you refer, the more rewards you may get. Ask your friends
										to join us and get your reward.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4 margin-t-20">
						<div class="services-box">
							<div class="media">
								<i class="pe-7s-piggy text-custom"></i>
								<div class="media-body ml-4">
									<h4>Monthly Transaction Program
									</h4>
									<p class="pt-2 text-muted">When your monthly transaction volume with us reaches a
										certain amount, we will give you corresponding rewards according to the tiered
										reward system.
										Every month we reward many clients up to millions of rewards. The higher the
										monthly transaction volume, the higher the reward!
										QingFeng considers good behavior to be honest trading, sending the valid cards,
										not sharing cards to others and keeping the trade on QingFeng, etc.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-4 margin-t-20">
						<div class="services-box">
							<div class="media">
								<i class="pe-7s-science text-custom"></i>
								<div class="media-body ml-4">
									<h4>How can I participate in the programs?
									</h4>
									<p class="pt-2 text-muted">Please click the "Get Started" or "Sell Now" buttons to
										contact with us on WhatsApp, we will introduce the program and record your
										information in our system.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4 margin-t-20">
						<div class="services-box">
							<div class="media">
								<i class="pe-7s-news-paper text-custom"></i>
								<div class="media-body ml-4">
									<h4>Can I be a part of both programs?
									</h4>
									<p class="pt-2 text-muted">Yes! You can be part of both the Referral Program and the
										Monthly Transaction Program at the same time.
										You can learn more about our rewards programs on WhatsApp.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4 margin-t-20">
						<div class="services-box">
							<div class="media">
								<i class="pe-7s-plane text-custom"></i>
								<div class="media-body ml-4">
									<h4>How can I keep track of my progress?
									</h4>
									<p class="pt-2 text-muted">You can track your progress on WhatsApp.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-4 margin-t-20">
						<div class="services-box">
							<div class="media">
								<i class="pe-7s-arc text-custom"></i>
								<div class="media-body ml-4">
									<h4>Pixel Perfect Design</h4>
									<p class="pt-2 text-muted">There are many variations of passages of Lorem Ipsum
										available, but the majority have suffered alteration.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4 margin-t-20">
						<div class="services-box">
							<div class="media">
								<i class="pe-7s-tools text-custom"></i>
								<div class="media-body ml-4">
									<h4>Progress</h4>
									<p class="pt-2 text-muted">Please confirm your progress with us when your whatsapp
										contacts change.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4 margin-t-20">
						<div class="services-box">
							<div class="media">
								<i class="pe-7s-timer text-custom"></i>
								<div class="media-body ml-4">
									<h4>Warning</h4>
									<p class="pt-2 text-muted">Using multiple accounts to participate in the reward
										program will result in disqualification.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--START SERVICES-->

		<!--START WEBSITE-DESCRIPTION-->
		<!-- 	<section class="section bg-web-desc"
			:style="{'background-image': 'url(' + require('@/assets/images/img-1.jpg') + ')', 'background-size': 'cover','background-position': 'center'}">
			<div class="bg-overlay"></div>
			<div class="container">
				<div class="row">
					<div class="col-lg-12 text-center">
						<h2 class="text-white">Build your dream website today</h2>
						<p class="padding-t-15 home-desc mx-auto">But nothing the copy said could convince her and so it
							didn’t take long until a few insidious Copy Writers ambushed her.</p>
						<a href="#" class="btn btn-bg-white margin-t-30">View Plan & Pricing</a>
					</div>
				</div>
			</div>
		</section> -->
		<!--END WEBSITE-DESCRIPTION-->
	</div>
</template>