<script>
	/**
	 * Footer component
	 */
	import {
		mapActions
	} from "vuex"
	export default {
		name: 'Footer',
		methods: {
			...mapActions(['loadChats', 'clickChat']),
			toChat() {
				this.clickChat()
			},
		}
	}
</script>

<template>
	<div>
		<!--START FOOTER-->
		<footer class="footer">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 margin-t-20">
						<h4>QingFeng</h4>
						<div class="text-muted margin-t-20">
							<ul class="list-unstyled footer-list">
								<li>
									<a v-scroll-to="{ el:'#home'}" class="nav-link" data-scroll-spy-id="home"
										href="javascript: void(0);">Home</a>
								</li>
								<li>
									<a v-scroll-to="{ el:'#rates', offset: -5}" class="nav-link"
										data-scroll-spy-id="rates" href="javascript: void(0);">Exchange Rates</a>
								</li>
								<li>
									<a v-scroll-to="{ el:'#rewards', offset: -5}" class="nav-link"
										data-scroll-spy-id="rewards" href="javascript: void(0);">Rewards</a>
								</li>
							</ul>
						</div>
					</div>
					<!-- <div class="col-lg-3 margin-t-20">
						<h4>Information</h4>
						<div class="text-muted margin-t-20">
							<ul class="list-unstyled footer-list">
								<li>
									<a href="#">Terms & Condition</a>
								</li>
								<li>
									<a href="#">About us</a>
								</li>
								<li>
									<a href="#">Jobs</a>
								</li>
								<li>
									<a href="#">Bookmarks</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-3 margin-t-20">
						<h4>Support</h4>
						<div class="text-muted margin-t-20">
							<ul class="list-unstyled footer-list">
								<li>
									<a href>FAQ</a>
								</li>
								<li>
									<a href>Contact</a>
								</li>
								<li>
									<a href>Disscusion</a>
								</li>
							</ul>
						</div>
					</div> -->
					<div class="col-lg-6 margin-t-20">
						<h4>To Connect Us</h4>
						<div class="text-muted margin-t-20">
							<p>Redsu is a trusted platform for redeeming various gift cards at great prices, we focus
								on providing our customers with an impeccable customer experience and consistent profit
								growth.</p>
						</div>
						<form class="form subscribe">
							<input placeholder="Enter Phone Number" class="form-control" required />
							<a href="#" class="submit" @click="toChat">
								<i class="pe-7s-paper-plane"></i>
							</a>
						</form>
					</div>
				</div>
			</div>
		</footer>
		<!--END FOOTER-->

		<!--START FOOTER ALTER-->
		<div class="footer-alt">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="text-center pull-none">
							<p class="copy-rights text-muted mb-3 mb-sm-0"> {{ new Date().getFullYear()}} © QingFeng -
								Gift Cards</p>
						</div>
						<div class="float-right pull-none">
							<!-- <ul class="list-inline social m-0">
                <li class="list-inline-item">
                  <a href class="social-icon">
                    <i class="mdi mdi-facebook"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href class="social-icon">
                    <i class="mdi mdi-twitter"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href class="social-icon">
                    <i class="mdi mdi-linkedin"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href class="social-icon">
                    <i class="mdi mdi-google-plus"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href class="social-icon">
                    <i class="mdi mdi-dribbble"></i>
                  </a>
                </li>
              </ul> -->
						</div>
						<div class="clearfix"></div>
					</div>
				</div>
			</div>
		</div>
		<!--START FOOTER ALTER-->
	</div>
</template>