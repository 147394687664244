<script>
	/**
	 * About component
	 */
	import Bscroll from 'better-scroll'
	export default {
		name: 'Cards',
		data() {
			return {
				scroll: null,
				cards: [{
						"id": 2,
						"name": "Steam",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgy_bIpgYokITCBzCYBDiGBg.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgy_bIpgYokITCBzCYBDiGBg.jpg",
						"content": "<p><br></p>",
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAgqLT8pAYo_s2jtAMwnAI4sQE.png",
						"sort": 1,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 1,
						"name": "iTunes",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgqOfIpgYouKju-wUwhAc4wAw.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgpufIpgYo7czCzgUwsAo4wAw.jpg",
						"content": "<p><br></p>",
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAgzrD8pAYooL2GxQQwvAU4uAM.png",
						"sort": 2,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 4,
						"name": "Razer",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgufLIpgYo9OaVsAUw7gU46Ac.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgufLIpgYo9OaVsAUw7gU46Ac.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg37D8pAYo-JPHkAMwrAI4vgE.png",
						"sort": 4,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 5,
						"name": "Xbox",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg4bD8pAYo66-MlQIwvAU4ugM.png",
						"sort": 5,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 6,
						"name": "AE",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgu-DIpgYo0KvzgQcw7gU46Ac.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgvPDIpgYo4Ob1oAUw7gU46Ac.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAg4rX8pAYopIei6QMwrAI4wAE.jpg",
						"sort": 6,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 7,
						"name": "Vanilla",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAg_OrIpgYoxqX5xQQwqgY4qgY.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAg9urIpgYossGagAUwqgY4jAU.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgo7b8pAYoorbmkAYwqAI4tAE.jpg",
						"sort": 7,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 8,
						"name": "Amazon",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg2LD8pAYonJbRggYw4wM4rQI.png",
						"sort": 8,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 9,
						"name": "Nordstrom",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgjOnIpgYo0-zs4gQwwAc4gAo.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgiunIpgYopN-w1AQwwAc4gAo.jpg",
						"content": "<p>spending  2hours</p>",
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg07D8pAYogtqbpQMw2AQ4_QI.png",
						"sort": 9,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 10,
						"name": "Ebay",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgs-HIpgYolv-23wYwxAc4lQQ.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgtPHIpgYogobkMzCACjiACg.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg0LD8pAYo2ebi4AQw9AM4mAI.png",
						"sort": 10,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 11,
						"name": "Nike",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgxe7IpgYogOK33wQwwAc4gAo.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgk_-IpgYo2K6z5gQwwAc4gAo.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg2rD8pAYohICX4AMwygI41gE.png",
						"sort": 11,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 12,
						"name": "Sephora",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAg3_jIpgYo5e_1lAUwwAc4gAo.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAg3ejIpgYo0KnmngIwuQY4uAg.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg0bD8pAYooM239AUwhAQ4wAI.png",
						"sort": 12,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 14,
						"name": "Gamestop",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgifDIpgYouo-8-gIwwAc4gAo.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgh-DIpgYouePMggUwwAc4gAo.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg3bD8pAYoxKP26Qcw4A846As.png",
						"sort": 14,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 15,
						"name": "Foot Locker",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgw_7IpgYo8sXv7wEwsAk4wAw.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgxe7IpgYogOK33wQwwAc4gAo.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg1bD8pAYojNXHvAIwogc4sgQ.png",
						"sort": 15,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 16,
						"name": "Macy's",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAg6_nIpgYog5PzKjCwCTjADA.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAg6unIpgYo4NzTowQwgAY4hQU.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg1rD8pAYoldfYiwYw2AQ4kQM.png",
						"sort": 16,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 17,
						"name": "Roblox",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgprj8pAYoivOwrQYwrAI4qAE.jpg",
						"sort": 17,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 18,
						"name": "VISA&Walmart",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgze3IpgYoqL-LvQQwgAY4rgQ.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgzu3IpgYolteougEwgAY4jAQ.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAg0rj8pAYovJWw-wUwmwI4sgE.jpg",
						"sort": 18,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 19,
						"name": "CVS",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg77D8pAYomNGTzQcw7QE4lgE.png",
						"sort": 19,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 3,
						"name": "Google",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgl_jIpgYo8uqrZTCqBji4CA.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgl_jIpgYo8uqrZTCqBji4CA.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAggLX8pAYo0IT2hgIw3wI48QE.png",
						"sort": 20,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 20,
						"name": "Target",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg47D8pAYosJO7DDCdAjixAQ.png",
						"sort": 20,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 21,
						"name": "Adidas",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg_bD8pAYo2Lb50wcwogI4rgE.png",
						"sort": 21,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 22,
						"name": "Visa 4030",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgze3IpgYoqL-LvQQwgAY4rgQ.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgzu3IpgYolteougEwgAY4jAQ.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAg6bn8pAYopM217AMw_gE4nwE.jpg",
						"sort": 22,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 23,
						"name": "Home Depot",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg9bD8pAYoyOCjgQIw2AQ4_QI.png",
						"sort": 23,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 24,
						"name": "Netspend",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg9LD8pAYo2LDzfDC2BzjKBA.png",
						"sort": 24,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 25,
						"name": "MichaelKors",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg8LD8pAYowrvkDTDtATiWAQ.png",
						"sort": 25,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 26,
						"name": "Amex Serve",
						"preview1": "1",
						"preview2": "1",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg8rD8pAYo2NCN1AMwwgM4_gE.png",
						"sort": 26,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 27,
						"name": "SFA",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg7bD8pAYovJq_pgIwuAM4pgI.png",
						"sort": 27,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 28,
						"name": "Bloomingdales",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg5rD8pAYoyMKMnwcw7QE4lgE.png",
						"sort": 28,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 29,
						"name": "Netflix",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg5LD8pAYo7duv9AMw9AM4wAI.png",
						"sort": 29,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 30,
						"name": "Walmart",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg67D8pAYo_Kid8QQwmgI4swE.png",
						"sort": 30,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 31,
						"name": "Best Buy",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg6rD8pAYowo2j_AUwugQ4kAM.png",
						"sort": 31,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 32,
						"name": "JC Penny",
						"preview1": null,
						"preview2": null,
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg6LD8pAYozKqwuwMwgB448BA.png",
						"sort": 32,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					},
					{
						"id": 33,
						"name": "Visa",
						"preview1": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgze3IpgYoqL-LvQQwgAY4rgQ.jpg",
						"preview2": "//27962583.s21i.faimallusr.com/2/ABUIABACGAAgzu3IpgYolteougEwgAY4jAQ.jpg",
						"content": null,
						"pic": "//27962583.s21i.faimallusr.com/4/ABUIABAEGAAg27D8pAYo7c_DhgQw7AQ4iAM.png",
						"sort": 33,
						"createTime": "2023-06-05 14:10:10",
						"rate": null,
						"ngn": 0.0,
						"ghs": 0.0,
						"cny": 0.0
					}
				]
			}
		},
		mounted() {
			this.$nextTick(() => {
				setTimeout(function() {
					this.scroll = new Bscroll('.scroll-wrapper', {
						scrollX: true,
						startX: 0,
						click: true,
						scrollY: false,
						snap: {
							loop: true,
							autoplay: true,
							threshold: 0.3,
							speed: 100
						},
					})
				}, 10)
			})
		}
	}
</script>

<template>
	<!--START ABOUT-US-->
	<section class="section" id="cards">
		<div class="container">
			<div class="col-lg-12  ">
				<div class="horizontal-container">
					<div class="scroll-wrapper " ref="scroll">
						<div class="scroll-content ">
							<div class="scroll-item  " v-for="(item, index) in cards" :key="index">
								<img :src="item.pic" class="card-img" />
								<div>{{item.name}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--END ABOUT-US-->
</template>
<style scoped>
	.scroll-wrapper {
		position: relative;
		margin: 80px auto;
		white-space: nowrap;
		border-radius: 5px;
		overflow: hidden;
	}
	.section{
		padding-top: 20px;
	}

	.scroll-item {
		height: 50px;
		line-height: 50px;
		display: inline-block;
		font-size: 24px;
		text-align: center;
		padding: 0 10px;
		width: 15rem;
		height: 11rem;
		margin-right: 20px;
	}

	.scroll-content {
		display: inline-block;
	}

	.card-img {
		width: 15rem;
		height: 10rem;

	}
</style>