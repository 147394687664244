<script>
	import Navbar from "@/components/navbar";
	import Features from "@/components/features";
	import Reward from "@/components/Rewards";
	import About from "@/components/about";
	import Pricing from "@/components/pricing";
	import Testimonial from "@/components/testimonial";
	import GetStarted from "@/components/getstarted";
	import Blog from "@/components/blog";
	import Contact from "@/components/contact";
	import Footer from "@/components/footer";
	import Switcher from "@/components/switcher";
	import Cards from "@/components/cards";
	import Rate from "@/components/rate";
	import {
		currentGET
	} from "@/api/index";
	import {
		mapActions
	} from "vuex"

	/**
	 * Index-9 component
	 */
	export default {
		components: {
			Navbar,
			Pricing,
			Testimonial,
			Cards,
			Rate,
			Reward,
			Footer,
		},
		data() {
			return {
				cards: []
			}

		},
		mounted() {
			this.getChats();

		},
		methods: {
			...mapActions(['loadChats', 'clickChat']),
			toChat() {
				this.clickChat()
			},
			getChats() {
				this.loadChats();
			},
		}
	};
</script>
<template>
	<div>
		<Navbar />
		<div v-scroll-spy>
			<!--START HOME-->
			<section class="section bg-home " id="home"
				:style="{ 'background-size': 'cover','background-position': 'center' }">
				<div class="bg-overlay"></div>
				<div class>
					<div class>

					</div>
				</div>
				<div class="container">
					<div class="row top-row">
						<div class="col-md-6 ">
							<div class="  text-white text-center">
								<h1 class="home-title">Sell Your Gift Card for a Higher Price!</h1>
								<p class="padding-t-15 home-desc mx-auto">Redsun™ redeem gift cards at the best
									exchange rates and price. Fast payments in Naira via bank transfer. Get the most
									value of gift cards now!.</p>
								<a href="javascript: void(0);" class="btn btn-custom margin-t-30" @click="toChat">Get
									Started</a>
								<a v-scroll-to="{ el:'#rates', offset: -5}"
									class="btn btn-custom margin-t-30 margin-l-30" data-scroll-spy-id="rates"
									href="javascript: void(0);">Rates</a>
								<div class="mt-5">
									<iframe src="//27962583.s21v.faimallusr.com/58/ABUIABA6GAAgu5yXrAYow5blQg.mp4"
										width="555" height="321" class="frame-border"></iframe>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="index-top-img">
								<img src="//27962583.s21i.faimallusr.com/4/ABUIABAEGAAgg4unrAYozaal4gYwpwQ4ywQ.png">
							</div>
						</div>
					</div>
				</div>
				<!-- Particles -->
				<!-- <vue-particles class="position-relative" color="#FFF"></vue-particles> -->
				<div class="wave-effect wave-anim">
					<div class="waves-shape shape-one">
						<div class="wave wave-one"
							:style="{'background-image': 'url(' + require('@/assets/images/wave-shape/wave1.png') + ')'}">
						</div>
					</div>
					<div class="waves-shape shape-two">
						<div class="wave wave-two"
							:style="{'background-image': 'url(' + require('@/assets/images/wave-shape/wave2.png') + ')'}">
						</div>
					</div>
					<div class="waves-shape shape-three">
						<div class="wave wave-three"
							:style="{'background-image': 'url(' + require('@/assets/images/wave-shape/wave3.png') + ')'}">
						</div>
					</div>
				</div>
			</section>
			<!-- <Cards></Cards> -->
			<Rate></Rate>
			<Reward></Reward>
			<!--END HOME-->
			<!-- <section class="section-sm bg-light">
				<div class="container">
					<div class="row">
						<div class="col-md-3">
							<div class="client-images my-3 my-md-0">
								<img src="https://cardyep.s3.eu-central-1.amazonaws.com/1669040789545.png"
									alt="logo-img" class="mx-auto img-fluid d-block" />
							</div>
						</div>
						<div class="col-md-3">
							<div class="client-images my-3 my-md-0">
								<img src="@/assets/images/clients/2.png" alt="logo-img"
									class="mx-auto img-fluid d-block" />
							</div>
						</div>
						<div class="col-md-3">
							<div class="client-images my-3 my-md-0">
								<img src="@/assets/images/clients/3.png" alt="logo-img"
									class="mx-auto img-fluid d-block" />
							</div>
						</div>

						<div class="col-md-3">
							<div class="client-images my-3 my-md-0">
								<img src="@/assets/images/clients/4.png" alt="logo-img"
									class="mx-auto img-fluid d-block" />
							</div>
						</div>
					</div>
				</div>
			</section> -->
			<!-- <Testimonial />
			<Pricing /> -->
			<!-- <Switcher /> -->
			<Footer />
			<!-- <div class="footer-alt">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="text-center pull-none">
								<p class="copy-rights text-muted mb-3 mb-sm-0"> {{ new Date().getFullYear()}} © QingFeng
									-
									Gift Cards</p>
							</div>
							<div class="float-right pull-none">
								<ul class="list-inline social m-0">
									<li class="list-inline-item">
										<a href class="social-icon">
											<i class="mdi mdi-facebook"></i>
										</a>
									</li>
									<li class="list-inline-item">
										<a href class="social-icon">
											<i class="mdi mdi-twitter"></i>
										</a>
									</li>
									<li class="list-inline-item">
										<a href class="social-icon">
											<i class="mdi mdi-linkedin"></i>
										</a>
									</li>
									<li class="list-inline-item">
										<a href class="social-icon">
											<i class="mdi mdi-google-plus"></i>
										</a>
									</li>
									<li class="list-inline-item">
										<a href class="social-icon">
											<i class="mdi mdi-dribbble"></i>
										</a>
									</li>
								</ul>
							</div>
							<div class="clearfix"></div>
						</div>
					</div>
				</div>
			</div> -->
		</div>
		<div class="fix-whatsapp">
			<img src="@/assets/images/whatsapp.png" alt="" @click="toChat" />
		</div>
	</div>
</template>

<style scoped>
	::v-deep .modal-content {
		background-color: #000000;
		color: white;
		background: none;
		border: none;
	}

	::v-deep .modal-header {
		border: none;
	}

	::v-deep .modal-backdrop {
		opacity: 0.5;
	}

	.index-top-img {
		width: 80%;
		height: auto;
		margin: 0 auto;

	}

	.index-top-img img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.top-row {
		align-items: center;
	}

	.fix-whatsapp {
		position: fixed;
		right: 20px;
		bottom: 30px;
		width: 60px;
		height: 60px;
		border-radius: 30px;
		overflow: hidden;
		text-align: center;
		background-color: #1BD741;
		box-shadow: 0 0 5px 5px rgba(27, 215, 65, .5);
		animation: blink 2s infinite;
	}

	@keyframes blink {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
		}

	}

	.fix-whatsapp img {
		width: 50px;
		height: 50px;
		margin-top: 5px;
	}
</style>