<script>
	export default {
		name: 'Pricing'
	}
</script>
<template>
	<!--START PRICING-->
	<section class="section bg-green" id="pricing">
		<div class="container">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<h1 class="section-title font-white text-center">We Say 'NO' </h1>
					<h1 class="section-title font-white text-center"> To Gift Card Wastage.</h1>
					<div class="section-title-border margin-t-20"></div>
					<p class="section-subtitle font-white  text-muted  padding-t-30">About $3 billion
						worth of gift cards were unused lastyear. This means a lot of people get hold of giftcardscard,
						which is wasteful. Until recently, that was amajor problem, which is where Rex steps in
						astheknight in shining armor.Rex also allows users to sell gift cards in Ghanaand Nigeria
						instantly. With thisinnovative provision.gift cards will never go to waste, even when they
						cannot be used in certain ar-eas,users know where to sell gift cards in Nigeria and Ghana at the
						bestpossible gift card rates Rex re.mains the best site to sell gift cards in Nigeria,including
						but not limited to iTunes, AmazonSteam Wa-let,Google Play, Apple Store. eBay.Walmart , Sephora,
						OneVanilla, Nordstrom, Target JCPenney, BestBuy, Nike, Hotels.com,Macy's,Gamestop.Xbox.Vanilla .
						G2A.American Express (AMEX), OffGamers, FootLockerVisa , Play Station and other gift cards with
						highest rates in Nigeria and Ghana.</p>
				</div>
			</div>
		</div>
	</section>
	<!--ENd PRICING-->
</template>
<style scoped>
	.bg-green {
		background-color: #1bbc9d;
	}

	.font-white {
		color: #fff !important;
	}
</style>
