<script>
	/**
	 * Navbar component
	 */
	import {
		mapActions
	} from "vuex"
	export default {
		mounted: () => {
			window.onscroll = function() {
				onwindowScroll();
			};
			var navbar = document.getElementById("navbar");

			function onwindowScroll() {
				if (
					document.body.scrollTop > 40 ||
					document.documentElement.scrollTop > 40
				) {
					navbar.style.backgroundColor = "#272a33";
					navbar.style.padding = "10px";
				} else {
					navbar.style.backgroundColor = "";
					navbar.style.padding = "20px";
				}
			}
		},
		methods: {
			/**
			 * Toggle menu
			 */
			...mapActions(['loadChats', 'clickChat']),
			toChat() {
				this.clickChat()
			},
			toggleMenu() {
				document.getElementById("navbarCollapse").classList.toggle("show");
			}
		}
	};
</script>
<template>
	<!-- STRAT NAVBAR -->
	<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar">
		<div class="container">
			<!-- LOGO -->
			<a class="navbar-brand logo " href="/">
				<img src="//27962583.s21i.faimallusr.com/4/ABUIABAEGAAgw-_mrAYokvGr1QUwyAY4jgI.png" width="143px"
					height="44px" />
			</a>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
				aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"
				@click="toggleMenu()">
				<i class="mdi mdi-menu"></i>
			</button>
			<div class="collapse navbar-collapse" id="navbarCollapse">
				<ul class="navbar-nav navbar-center" id="mySidenav" v-scroll-spy-active="{class: 'active'}">
					<li class="nav-item">
						<a v-scroll-to="{ el:'#home'}" class="nav-link" data-scroll-spy-id="home"
							href="javascript: void(0);">Home</a>
					</li>
					<li class="nav-item">
						<a v-scroll-to="{ el:'#rates', offset: -5}" class="nav-link" data-scroll-spy-id="rates"
							href="javascript: void(0);">Exchange Rates</a>
					</li>
					<li class="nav-item">
						<a v-scroll-to="{ el:'#rewards', offset: -5}" class="nav-link" data-scroll-spy-id="rewards"
							href="javascript: void(0);">Rewards</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="javascript: void(0);"
							@click="toChat">WhatsApp</a>
					</li>
				</ul>
			</div>
		</div>
	</nav>
	<!-- END NAVBAR -->
</template>