<script>
	export default {
		name: 'Testimonial'
	}
</script>
<template>
	<!--START TESTIMONIAL-->
	<section class="section" id="testimonial">
		<div class="container">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<h1 class="section-title text-center">Our Advantages</h1>
					<div class="section-title-border margin-t-20"></div>
					<p class="section-subtitle text-muted text-center font-secondary padding-t-30">Best gift card
						trading application.</p>
				</div>
			</div>
			<div class="row margin-t-50">
				<div class="col-lg-4">
					<div class="testimonial-box margin-t-30">
						<div class="testimonial-decs p-4">
							<div class="testi-icon">
								<i class="mdi mdi-format-quote-open display-2"></i>
							</div>
							<img src="@/assets/images/monial/fast.png"
								class="img-fluid mx-auto d-block img-thumbnail rounded-circle mb-4" />
							<div class="p-1">
								<h5 class="text-center text-uppercase mb-3">
									Instant Payment
								</h5>
								<p class="text-muted text-center mb-0">“USing state-of-the-art payment procedures,you
									are guaranteed to get the payment for your gift card swap in minutes.”</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="testimonial-box margin-t-30">
						<div class="testimonial-decs p-4">
							<div class="testi-icon">
								<i class="mdi mdi-format-quote-open display-2"></i>
							</div>
							<img src="@/assets/images/monial/9j.png"
								class="img-fluid mx-auto d-block img-thumbnail rounded-circle mb-4" />
							<div class="p-1">
								<h5 class="text-center text-uppercase mb-3">
									Cross-Platform Availability
								</h5>
								<p class="text-muted text-center mb-0">“Sell gift card in Nigeria, anytime, using our
									modern gift card trading application available on Android, iOS and web browser.”</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="testimonial-box margin-t-30">
						<div class="testimonial-decs p-4">
							<div class="testi-icon">
								<i class="mdi mdi-format-quote-open display-2"></i>
							</div>
							<img src="@/assets/images/monial/safe.png"
								class="img-fluid mx-auto d-block img-thumbnail rounded-circle mb-4" />
							<div class="p-1">
								<h5 class="text-center text-uppercase mb-3">
									Trusted And Secure
								</h5>
								<p class="text-muted text-center mb-0">“Trade gift card with confidence, we assure you
									the highest level of encryption and professionally audited exchange system.
									”</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--END TESTIMONIAL-->
</template>
